body {
  margin: 0;
}

.connectPointsWrapper {
  opacity: 0.2;
}

.connectPointsWrapper:hover {
  opacity: 0.6;
}

.context-menu {
  font-size: 14px;
  background-color: #fff;
  border-radius: 2px;
  padding: 5px 0 5px 0;
  width: 150px;
  height: auto;
  margin: 0;
  /* use absolute positioning  */
  position: absolute;
  list-style: none;
  box-shadow: 0 0 20px 0 #ccc;
  opacity: 1;
  transition: opacity 0.5s linear;
  z-index: 100;
}

.context-menu li {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
}

.context-menu li:hover {
  cursor: pointer;
  opacity: 0.8;
}

.context-menu li span {
  margin-left: 10px;
}

.identifier-wrap {
  position: relative;
}

.identifier-code {
  position: absolute;
}

.identifier-code.text {
  left: 10px;
  top: 35%;
}

.identifier-code.media {
  bottom: 10px;
  right: 15px;
}
